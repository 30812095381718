import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { payrollHistoryStatusConfig, PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetPayslip } from '../../redux/payroll/hook/index';
import DashboardLayout from '../../components/layouts/dashboard';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import ReactDOMServer from 'react-dom/server';
import { store } from '../../redux';
import { setIsLoading } from '../../redux/components/components-slice';
import { useSelector } from 'react-redux';
import { toDataUrl } from '../../utils/functions';
import AdvancedPayrollDetailedView from '../../components/modal/payroll/payroll-full-breakdown-view';
import PayslipPDFContent from '../../utils/pdf-content/payslip';

const PayrollPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { company } = useSelector((state) => state.companySlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    start_date: '',
    end_date: '',
  });

  const [showpayslipHistoryDetailedView, setShowpayslipHistoryDetailedView] = useState(false);
  const [previewData, setPreviewData] = useState()
  const [imageBlob, setImageBlob] = useState(null)
  const [downloadPayslipLoading, setDownloadPayslipLoading] = useState(false)

  const changeImage = async (imageLink) => {
    let urlBase64 = await toDataUrl(imageLink);
    setImageBlob(urlBase64)
  }

  useEffect(() => {
    if (company) {
      changeImage(company.logo)
    }
    return setImageBlob(null)
  }, [company])

  const printHandler = (index) => {
    setDownloadPayslipLoading(true);
    const printElement = ReactDOMServer.renderToString(
      PayslipPDFContent(company, payslipHistory.data[index], imageBlob)
    );
    var opt = {
      margin: 0,
      filename: payslipHistory.data[index]?.ref + ' - payslip.pdf',
      image: { type: '', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      pagebreak: { mode: "avoid-all", before: "#page2el" },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      enableLinks: true
    };
    html2pdf().set(opt).from(printElement).save();
    setDownloadPayslipLoading(false);
  }

  const { data: payslipHistory } = useGetPayslip(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
  )

  const tableConfig = {
    headers: ['Payroll month', 'Bank Info', 'Description', 'Status', 'Net Pay'],
    keys: ['month', 'bank_info', 'description', 'status', 'formatted_net_pay'],
    mobileHeader: {
      left: {
        title: 'Description',
        key: 'description',
      },
      right: {
        title: 'Net Pay',
        key: 'formatted_net_pay',
      },
    },
  };

  const statusConfig = [
    {
      label: 'Not Processed',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Processed',
      color: 'green',
      value: 1,
    },
  ];

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: async (id) => {
        let index = payslipHistory.data.findIndex((item) => item.id === id);
        setPreviewData(payslipHistory.data[index])
        setShowpayslipHistoryDetailedView(true)
      },
    },
    {
      name: 'Download Payslip',
      color: 'red',
      action: (id) => {
        let index = payslipHistory.data.findIndex((item) => item.id === id);
        if (index !== -1) {
          printHandler(index)
        }
      },
    },
  ];



  return (
    <DashboardLayout title='My Payslips'>
      {previewData && showpayslipHistoryDetailedView ?
        <AdvancedPayrollDetailedView
          isOpen={showpayslipHistoryDetailedView}
          closeModal={() => setShowpayslipHistoryDetailedView(false)}
          viewData={previewData}
        />
        :
        null
      }
      <div className='flex flex-col bg-white shadow-md rounded-md'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            Payslip History
          </div>
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={payslipHistory}
          noCheck={true}
          tableConfig={tableConfig}
          filterByStatus={payrollHistoryStatusConfig}
          statusConfig={statusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default PayrollPage;
