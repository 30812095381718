import React from 'react';
import moment from 'moment';
import { formatNumber } from '../functions';

const PayslipPDFContent = (company, payslip, imageBlob = null) => {
    return (
        <div className="w-full min-h-screen bg-white p-8">
            <div className="max-w-7xl mx-auto space-y-6">
                {/* Header Section */}
                <div className="flex justify-between items-start">
                    {/* Company Info */}
                    <div className="space-y-4">
                        <div className="text-lg flex font-bold justify-center items-center h-14 w-16 relative rounded-xl bg-white">
                            {company?.logo ? (
                                <img
                                    src={imageBlob}
                                    alt="company_logo"
                                    className="object-fit min-w-[60px] min-h-[45px] max-w-[60px] max-h-[45px]"
                                />
                            ) : (
                                <span className="text-primary-1">
                                    {company?.company_name?.charAt(0)}
                                </span>
                            )}
                        </div>
                        <div className="space-y-2">
                            <p className="text-sm text-black font-normal">
                                {company?.address}, {company?.country}
                            </p>
                            <p className="text-sm text-black font-medium">
                                {company?.email} | {company?.mobile}
                            </p>
                        </div>
                    </div>

                    {/* Payslip Info */}
                    <div className="flex gap-4">
                        <div className="space-y-4">
                            <p className="text-sm font-medium">Pay Month:</p>
                            <p className="text-sm font-medium">Date of Payment:</p>
                            {payslip.staff_number &&
                                <p className="text-sm font-medium">Staff Number:</p>}
                        </div>
                        <div className="space-y-4">
                            <p className="text-sm font-normal">
                                {moment(payslip.month).format('MMMM, YYYY')}
                            </p>
                            <p className="text-sm font-normal">
                                {moment(payslip.payment_date).format('MMMM DD, YYYY')}
                            </p>
                            {payslip.staff_number &&
                                <p className="text-sm font-normal">
                                    {payslip.staff_number || '-'}
                                </p>
                            }

                        </div>
                    </div>
                </div>

                {/* Employee Info */}
                <div className="flex justify-between items-start">
                    <div className="space-y-2">
                        <h2 className="text-lg font-semibold">
                            {payslip.employee_first_name} {payslip.employee_last_name}
                        </h2>
                        <p className="text-sm font-medium">
                            {payslip.employee_email}
                        </p>
                        <p className="text-sm font-medium">
                            {payslip.employee_phone}
                        </p>
                    </div>
                    <div className="space-y-2 text-right">
                        <h2 className="text-lg font-semibold">
                            {payslip.bank_name}
                        </h2>
                        <p className="text-sm font-medium">
                            {payslip.account_number}
                        </p>
                        <p className="text-sm font-medium">
                            {payslip.account_name}
                        </p>
                    </div>
                </div>

                {/* Earnings Section */}
                <div className="space-y-4">
                    <h3 className="text-xl font-bold text-[#001984]">
                        EARNINGS
                    </h3>
                    <div>

                        <div className="px-6 h-12 pb-2 bg-[#001984]/10 flex flex-row justify-between items-center w-full">
                            <span className="flex h-fit text-[#001984] text-base font-semibold pb-2">Type</span>
                            <span className="flex h-fit text-right text-[#001984] text-base font-semibold pb-2">Amount</span>
                        </div>

                        <div className="px-6 py-3">
                            <div className="flex justify-between">
                                <div className="space-y-3">
                                    {payslip.basic_salary > 0 && (
                                        <p className="text-sm font-normal">Basic Salary</p>
                                    )}
                                    {payslip.housing_allowance > 0 && (
                                        <p className="text-sm font-normal">Housing Allowance</p>
                                    )}
                                    {payslip.transport_allowance > 0 && (
                                        <p className="text-sm font-normal">Transport Allowance</p>
                                    )}
                                    {payslip.leave_allowance > 0 && (
                                        <p className="text-sm font-normal">Leave Allowance</p>
                                    )}
                                    {payslip.utility_allowance > 0 && (
                                        <p className="text-sm font-normal">Utility Allowance</p>
                                    )}
                                    {payslip.lunch_allowance > 0 && (
                                        <p className="text-sm font-normal">Lunch Allowance</p>
                                    )}
                                    {payslip.overtime > 0 && (
                                        <p className="text-sm font-normal">Overtime</p>
                                    )}
                                    <p className="text-sm font-bold">Gross Pay</p>
                                </div>
                                <div className="space-y-3 text-right">
                                    {payslip.basic_salary > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.basic_salary)}
                                        </p>
                                    )}
                                    {payslip.housing_allowance > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.housing_allowance)}
                                        </p>
                                    )}
                                    {payslip.transport_allowance > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.transport_allowance)}
                                        </p>
                                    )}
                                    {payslip.leave_allowance > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.leave_allowance)}
                                        </p>
                                    )}
                                    {payslip.utility_allowance > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.utility_allowance)}
                                        </p>
                                    )}
                                    {payslip.lunch_allowance > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.lunch_allowance)}
                                        </p>
                                    )}
                                    {payslip.overtime > 0 && (
                                        <p className="text-sm font-normal">
                                            {formatNumber(payslip.overtime)}
                                        </p>
                                    )}
                                    <p className="text-sm font-bold">
                                        {formatNumber(payslip.gross_pay)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Deductions Section */}
                <div className="space-y-4">
                    <h3 className="text-xl font-bold text-[#001984]">
                        DEDUCTIONS
                    </h3>
                    <div>
                        <div className="px-6 h-12 pb-2 bg-[#001984]/10 flex flex-row justify-between items-center w-full">
                            <span className="flex h-fit text-[#001984] text-base font-semibold pb-2">Type</span>
                            <span className="flex h-fit text-right text-[#001984] text-base font-semibold pb-2">Amount</span>
                        </div>

                        <div className="px-6 py-3">
                            <div className="flex justify-between">
                                <div className="space-y-3">
                                    <p className="text-sm font-normal">PAYE Tax</p>
                                    <p className="text-sm font-normal">Pension</p>
                                    <p className="text-sm font-bold">Total Deductions</p>
                                </div>
                                <div className="space-y-3 text-right">
                                    <p className="text-sm font-normal">
                                        {formatNumber(payslip.tax)}
                                    </p>
                                    <p className="text-sm font-normal">
                                        {formatNumber(payslip.pension)}
                                    </p>
                                    <p className="text-sm font-bold">
                                        {formatNumber(payslip.tax + payslip.pension)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Net Pay Section */}
                <div className="px-6 h-12 pb-2 bg-[#001984]/10 flex flex-row justify-between items-center w-full">
                    <p className="text-[#001984] text-base font-bold">
                        Net Pay
                    </p>
                    <p className="text-[#001984] text-lg font-bold">
                        {formatNumber(payslip.net_pay)}
                    </p>
                </div>

                {/* Add android and ios button  */}
                <div className="flex justify-center items-center space-x-4 mb-2">
                    <a href="https://play.google.com/store/apps/details?id=com.ropay.app&hl=en" target="_blank" rel="noreferrer">
                        <img src="/playstore.png" alt="Google Play Badge" className="h-12" />
                    </a>
                    <a href="https://apps.apple.com/ng/app/ropay-mobile/id1673798586" target="_blank" rel="noreferrer">
                        <img src="/appstore.png" alt="App Store Badge" className="h-12" />
                    </a>
                </div>


                {/* Footer */}
                <a href="https://ropay.africa" target="_blank" rel="noreferrer" className="text-sm text-center text-[#001984] font-normal">
                    <div className="text-center space-y-2">
                        <p className="text-sm font-normal">
                            Powered By
                        </p>
                        <img src="/ropay-blue.svg" alt="Ropay Logo" className="h-8 mx-auto" />
                    </div>
                </a>
            </div>
        </div>
    );
};

export default PayslipPDFContent;